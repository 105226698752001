.banner-spacer {
  height: 50px !important;
  background-color: transparent !important;
}

.spacer-25 {
  height: 25px !important;
  background-color: transparent !important;
}

.spacer-100 {
  height: 100px !important;
  background-color: transparent !important;
}

.third-color-span {
  color: #9d9e9f;
}

.landing-custom-header2 {
  font-size: 3vw !important;
  font-weight: bold;
}

.secondary-color-span {
  color: #f89422;
}

.p-header-font {
  font-size: 1.2rem !important;
  font-weight: bold;
}

.landing-paragraph,
.start-step-div {
  width: 80%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.p-font {
  font-size: 1.1rem !important;
}

.start-step-div {
  margin-top: 50px;
}

.comenzar-button-div {
  margin-top: 50px;
}

.comenzar-button {
  background-color: #f89422 !important;
  height: 50px;
  width: 200px;
  border: 1px solid #f89422 !important;
  border-radius: 0 !important;
  font-size: 1.1rem;
  font-weight: bold;
}

.comenzar-button:hover,
.comenzar-button:active,
.comenzar-button:after,
.comenzar-button:focus {
  background-color: #35363a !important;
  border: 1px solid #35363a !important;
}

.landing-din-alternate-bold-font {
  font-family: "DIN Alternate Bold" !important;
  font-weight: bold !important;
  font-size: 4.9vw !important;
}

.claves-para-exito-div{
  margin-left: auto;
  margin-right: auto;
  width: 80%;
}

.contact-banner{
  background-color:#f89422;
  height: 150px;
}

.contact-banner-title-h2{
  color: white !important;
  font-family: "DIN Condensed Bold" !important;
  font-size: 4.0vw !important;
  font-weight: bold !important;
  text-align: center;
  letter-spacing: 20px;
  line-height: 150px;
}

.contact-us-div{
  margin-left: auto;
  margin-right: auto;
  width: 80%;
}





@media screen and (max-width: 578px){
  
  .banner-spacer{
    height: 0px !important;
  }
  
  .landing-custom-header2 {
    font-size: 40px !important;
    font-weight: bold;
  }

  .landing-din-alternate-bold-font{
    font-size: 40px !important;
    font-weight: bold;
  }

  .landing-paragraph{
    margin-top: 50px;
    text-align: justify;
  }
  .contact-banner{
    height: 60px;
  }

  .contact-banner-title-h2{
    font-size: 30px!important;
    letter-spacing: 10px;
    line-height: 70px;
    margin-left:15px;
    margin-right: 15px;
  }

  .comenzar-button-div {
    margin-top: 25px;
  }
}